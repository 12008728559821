import { gql } from 'graphql-request';

export const getTelevisionBenefitsQuery = gql`
  query getTelevisionBenefits($billingCustomerId: String!) {
    getTelevisionBenefits(billingCustomerId: $billingCustomerId) {
      televisionPackageId
      name
      brand
      eligibility
      eligibilityReason
    }
  }
`;

export const getAssignedTelevisionBenefitQuery = gql`
  query getAssignedTelevisionBenefit($billingCustomerId: String!) {
    getAssignedTelevisionBenefit(billingCustomerId: $billingCustomerId) {
      changeAllowedAfterDate
      benefit {
        televisionPackageId
        name
        brand
        subscription {
          accountID
          partnerID
          tier
          purchaseDate
          endDate
          renewalDate
          status
          channel
          OTTstatus
          OTTactivationDate
          regularPrice
        }
      }
    }
  }
`;

export const getPendingTvBenefitNameQuery = gql`
  query getPendingTvBenefitName($billingCustomerId: String!) {
    getPendingTvBenefitName(billingCustomerId: $billingCustomerId) {
      name
    }
  }
`;

export const putAssignedTelevisionBenefitQuery = gql`
  mutation putAssignedTelevisionBenefit($billingCustomerId: String!, $televisionPackageId: String!) {
    putAssignedTelevisionBenefit(billingCustomerId: $billingCustomerId, televisionPackageId: $televisionPackageId) {
      success
    }
  }
`;
