import { ApiResponse, EmailAddress, EmailAddressInfo, EmailAliasApi, EmailApi } from 'api';
import { BillingCustomerContext } from 'context/billing-customer.context';
import first from 'lodash.first';
import isArray from 'lodash.isarray';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { useProductContext } from '../product/product.context';

export interface IEmailContext {
  isLoading: boolean;
  hasError: boolean;
  emailAddresses: EmailAddressInfo[] | null;
  maxEmailBoxesAllowed: number;
  maxAliasesPerEmailBoxAllowed: number;
  getEmailAddresses(): Promise<void>;
  createEmailAddress(emailAddress: EmailAddress): Promise<void>;
  changePasswordEmailAddress(emailAddress: string, password?: string): ApiResponse<{ success: boolean }>;
  deleteEmailAddress(emailAddress: string): Promise<void>;
  createEmailAddressAlias(emailAddress: string, alias: string): Promise<boolean>;
  deleteEmailAddressAlias(emailAddress: string, alias: string): Promise<void>;
}

interface ProductProviderProps {
  children: React.ReactNode;
}

const EmailContext = createContext<IEmailContext | undefined>(undefined);

const useEmailContext = () => useContext(EmailContext) as IEmailContext;

const EmailProvider: React.FC = ({ children }: ProductProviderProps) => {
  const billingCustomerContext = useContext(BillingCustomerContext);
  const { hasDashboardProductsLoaded } = useProductContext();
  const [isLoading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [emailAddresses, setEmailAddresses] = useState<EmailAddressInfo[] | null>(null);
  const [maxEmailBoxesAllowed, setMaxEmailBoxesAllowed] = useState(0);
  const [maxAliasesPerEmailBoxAllowed, setMaxAliasesPerEmailBoxAllowed] = useState(0);

  useEffect(() => {
    setLoading(true);
    if (hasDashboardProductsLoaded) getEmailAddresses();
  }, [hasDashboardProductsLoaded]);

  const getEmailAddresses = async (): Promise<void> => {
    if (!billingCustomerContext.completed) return;

    setLoading(true);
    setHasError(false);
    try {
      const response = await EmailApi.getEmailAddresses(billingCustomerContext.activeBcId);
      setEmailAddresses(response.data.emailAddresses);
      setMaxEmailBoxesAllowed(response.data.maxEmailBoxesAllowed);
      setMaxAliasesPerEmailBoxAllowed(response.data.maxAliasesPerEmailBoxAllowed);
    } catch (e) {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  const createEmailAddress = async (emailAddress: EmailAddress) => {
    try {
      await EmailApi.createEmailAddress(billingCustomerContext.activeBcId, emailAddress);
      await getEmailAddresses();
    } catch (error) {
      if (isArray(error?.message)) {
        throw first(error?.message);
      }

      throw error;
    }
  };

  const changePasswordEmailAddress = async (
    emailAddress: string,
    password?: string
  ): ApiResponse<{ success: boolean }> => {
    return EmailApi.changePasswordEmailAddress(billingCustomerContext.activeBcId, emailAddress, password);
  };

  const deleteEmailAddress = async (emailAddress: string) => {
    await EmailApi.deleteEmailAddress(billingCustomerContext.activeBcId, emailAddress);

    if (emailAddresses?.length) {
      const addresses = [...emailAddresses].filter((email) => email.emailAddress !== emailAddress);
      setEmailAddresses(addresses);
    }
  };

  const createEmailAddressAlias = async (emailAddress: string, alias: string): Promise<boolean> => {
    const [, domain] = emailAddress.split('@');
    const newAlias = `${alias}@${domain}`;

    const response = await EmailAliasApi.createEmailAddressAlias(
      billingCustomerContext.activeBcId,
      emailAddress,
      newAlias
    );

    if (response.data.success) {
      const emailWithAliases = (emailAddresses || []).map((email) => {
        if (email.emailAddress === emailAddress) {
          email.aliases.push(newAlias);
        }
        return email;
      });

      setEmailAddresses(emailWithAliases);
    }

    return response.data.success;
  };

  const deleteEmailAddressAlias = async (emailAddress: string, alias: string) => {
    await EmailAliasApi.deleteEmailAddressAlias(billingCustomerContext.activeBcId, emailAddress, alias);

    const emailWithAliases = (emailAddresses || []).map((email) => {
      if (email.emailAddress === emailAddress) {
        // eslint-disable-next-line no-param-reassign
        email.aliases = email.aliases.filter((a) => a !== alias);
      }
      return email;
    });

    setEmailAddresses(emailWithAliases);
  };

  return (
    <EmailContext.Provider
      value={{
        isLoading,
        hasError,
        emailAddresses,
        maxAliasesPerEmailBoxAllowed,
        maxEmailBoxesAllowed,
        getEmailAddresses,
        createEmailAddress,
        changePasswordEmailAddress,
        deleteEmailAddress,
        createEmailAddressAlias,
        deleteEmailAddressAlias,
      }}>
      {children}
    </EmailContext.Provider>
  );
};

export { EmailContext, EmailProvider, useEmailContext };
