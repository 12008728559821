import get from 'lodash.get';
import { IContent } from 'models/content.model';
import React from 'react';
import globalContent from 'static/globalContent/';

interface ITranslationVariables {
  [key: string]: string;
}

interface IContentUtilityOptions {
  content?: IContent;
  componentName: string;
}

export const createDataTestId = (title: string | undefined, prefix = ''): string => {
  // replace space, coma+space, space+&+space with -
  const regex = /( & *)|(, *)|( )/g;

  return `${prefix}${(title || '').toLowerCase().replace(regex, '-')}-card`;
};

class ContentUtil {
  private content: IContent = { nl: {}, en: {} };

  readonly componentName: string;

  constructor({ content = globalContent, componentName = 'global' }: IContentUtilityOptions) {
    this.componentName = componentName;
    if (this.componentName === 'global' && !content) {
      this.content = globalContent;
    } else {
      this.content = content;
    }
  }

  /**
   * Displays simple text, optionally with replaced variables. HTML is literally displayed.
   * @param key translation key
   * @param variables flat object of variables
   */
  translate(key: string, variables?: ITranslationVariables): React.ReactNode | string {
    const str = this.translateToString(key, variables);
    if (process.env.NODE_ENV === 'development') {
      return <span title={`${this.componentName} > ${key}`}>{str}</span>;
    }
    return <span>{str}</span>;
  }

  /**
   * Renders HTML, optionally with replaced variables
   * @param key translation key
   * @param variables flat object of variables
   */
  translateHtml(key: string, variables?: ITranslationVariables): React.ReactNode {
    const str = this.translateToString(key, variables);
    if (process.env.NODE_ENV === 'development') {
      return <span title={`${this.componentName} > ${key}`} dangerouslySetInnerHTML={{ __html: str }} />;
    }
    return <span dangerouslySetInnerHTML={{ __html: str }} />;
  }

  /**
   * Returns a string, optionally with replaced variables
   * @param key translation key
   * @param variables flat object of variables
   */
  translateToString(key: string, variables?: ITranslationVariables): string {
    // currently we only use NL translations, so no need to make using '.nl' more complex

    let str = get(this.content.nl, key);

    if (typeof str !== 'string') {
      return `[${key}]`;
    }

    if (variables) {
      Object.keys(variables).forEach((variableKey) => {
        str = (str as string).replace(new RegExp(`#{${variableKey}}`, 'g'), variables[variableKey]);
      });
    }

    return str;
  }

  hasTranslation(key: string): boolean {
    return !!get(this.content.nl, key);
  }
}

const globalContentUtil = new ContentUtil({ componentName: 'global' });

export { ContentUtil, globalContentUtil };
