import React, { SVGProps } from 'react';

export const TvAppsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6732 7.4619C15.6732 8.4534 16.4757 9.2559 17.4672 9.2559C18.4572 9.2559 19.2612 8.4534 19.2612 7.4619C19.2612 6.4704 18.4572 5.6664 17.4672 5.6664C16.4757 5.6664 15.6732 6.4704 15.6732 7.4619ZM15.6732 12.8724C15.6732 13.8639 16.4757 14.6664 17.4672 14.6664C18.4572 14.6664 19.2612 13.8639 19.2612 12.8724C19.2612 11.8809 18.4572 11.0784 17.4672 11.0784C16.4757 11.0784 15.6732 11.8809 15.6732 12.8724ZM10.2882 7.4619C10.2882 8.4534 11.0922 9.2559 12.0822 9.2559C13.0737 9.2559 13.8777 8.4534 13.8777 7.4619C13.8777 6.4704 13.0737 5.6664 12.0822 5.6664C11.0922 5.6664 10.2882 6.4704 10.2882 7.4619ZM10.2882 12.8724C10.2882 13.8639 11.0922 14.6664 12.0822 14.6664C13.0737 14.6664 13.8777 13.8639 13.8777 12.8724C13.8777 11.8809 13.0737 11.0784 12.0822 11.0784C11.0922 11.0784 10.2882 11.8809 10.2882 12.8724ZM4.9047 7.4619C4.9047 8.4534 5.70719 9.2559 6.69869 9.2559C7.69019 9.2559 8.49419 8.4534 8.49419 7.4619C8.49419 6.4704 7.69019 5.6664 6.69869 5.6664C5.70719 5.6664 4.9047 6.4704 4.9047 7.4619ZM4.9047 12.8724C4.9047 13.8639 5.70719 14.6664 6.69869 14.6664C7.69019 14.6664 8.49419 13.8639 8.49419 12.8724C8.49419 11.8809 7.69019 11.0784 6.69869 11.0784C5.70719 11.0784 4.9047 11.8809 4.9047 12.8724ZM-0.000305176 2.6664H19.4997C21.9867 2.6664 23.9997 4.6809 23.9997 7.1664V17.6664H4.49969C2.01419 17.6664 -0.000305176 15.6534 -0.000305176 13.1664V2.6664ZM9.00045 19.167H15.0004C16.6564 19.167 18.0004 20.511 18.0004 22.167H6.00044C6.00044 20.511 7.34445 19.167 9.00045 19.167Z"
      fill="#1C1D1E"
    />
  </svg>
);
